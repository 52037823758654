import React, { Component } from 'react';
import { Sidebar, Menu, Segment, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import PropTypes from 'prop-types';
import intersection from 'lodash/intersection';
import get from 'lodash/get';

import dashboardIcon from '../../../assets/images/dashboard-icon.svg';
import tripIcon from '../../../assets/images/planning-icon.svg';
import driverIcon from '../../../assets/images/driver-icon.svg';
import vehicleIcon from '../../../assets/images/vehicle-icon.svg';
import userIcon from '../../../assets/images/user-icon.svg';
import groupIcon from '../../../assets/images/group-icon.svg';
import peopleIcon from '../../../assets/images/people-icon.svg';
import registryIcon from '../../../assets/images/registry-icon.svg';
import pathIcon from '../../../assets/images/path.svg';
import locationPinIcon from '../../../assets/images/location-pin.svg';
import transactionIcon from '../../../assets/images/transaction.svg';
import ticketIcon from '../../../assets/images/bus.svg';
import settingsIcon from '../../../assets/images/settings.svg';
import weegoLogo from '../../../assets/images/weego-logo-with-text.png';
import colors from '../../../theme/colors';
import './SideMenu.css';
import withi18n from 'weego-common/src/hoc/i18n';

const items = [
  {
    id: 'dashboard',
    label: 'Dashboard',
    link: '/pro/dashboard',
    icon: dashboardIcon,
  },
  {
    id: 'trip',
    label: 'Planning',
    link: '/pro/trips',
    icon: tripIcon,
    roles: ['super_admin', 'admin', 'transporter'],
  },
  {
    id: 'registry',
    label: 'Registre',
    link: '/pro/registry',
    icon: registryIcon,
    roles: ['super_admin', 'admin'],
  },
  {
    id: 'drivers',
    label: 'Conducteurs',
    link: '/pro/drivers',
    icon: driverIcon,
    roles: ['super_admin', 'admin', 'transporter'],
  },
  {
    id: 'vehicles',
    label: 'Véhicules',
    link: '/pro/vehicles',
    icon: vehicleIcon,
    roles: ['super_admin', 'admin', 'transporter'],
  },
  {
    id: 'stops',
    label: 'Arrêts',
    link: '/pro/stops',
    icon: locationPinIcon,
    roles: ['super_admin', 'admin', 'client'],
  },
  {
    id: 'passengers',
    label: 'Passagers',
    link: '/pro/passengers',
    icon: userIcon,
    roles: ['super_admin', 'admin', 'client'],
  },
  {
    id: 'demands',
    label: 'Demandes',
    link: '/pro/demands',
    icon: vehicleIcon,
    roles: ['super_admin', 'admin', 'client'],
  },
  {
    id: 'accounts',
    label: 'Comptes',
    link: '/pro/accounts',
    icon: peopleIcon,
    roles: ['super_admin', 'admin', 'client', 'transporter'],
  },
  {
    id: 'groups',
    label: 'Groupes',
    link: '/pro/groups',
    icon: groupIcon,
    roles: ['super_admin', 'admin'],
  },
  {
    id: 'transactions',
    label: 'Transactions',
    link: '/pro/transactions',
    icon: transactionIcon,
    roles: ['super_admin'],
  },
  {
    id: 'tickets',
    label: 'Tickets',
    link: '/pro/tickets',
    icon: ticketIcon,
    roles: ['super_admin'],
  },
  {
    id: 'foughal-transactions',
    label: 'Tickets',
    link: '/pro/foughal-transactions',
    icon: ticketIcon,
    roles: ['super_admin'],
  },
  {
    id: 'customFields',
    label: 'Champs',
    link: '/pro/customFields',
    icon: settingsIcon,
    roles: ['super_admin', 'admin'],
  },
  {
    id: 'planner',
    label: 'Planificateur',
    link: '/pro/planner-v2',
    icon: pathIcon,
    roles: ['super_admin', 'admin'],
  },
];

class SideMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: true,
      activeItem: props.currentPath.toLowerCase().substr(5),
    };
  }

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
    if (window.screen.width <= 767) {
      const { toggleDrawer } = this.props;
      toggleDrawer(false);
    }
  };

  render() {
    const { children, opened, account, toggleDrawer, t } = this.props;
    const { activeItem } = this.state;
    const roles = get(account, 'roles', []);
    return (
      <Sidebar.Pushable
        className="side-menu"
        as={Segment}
        style={styles.pushable}
      >
        <Sidebar
          width={opened ? 'thin' : 'very thin'}
          visible
          style={styles.sidebar}
        >
          <Menu text style={styles.sideMenuPadding} vertical>
            <Image
              src={weegoLogo}
              style={{
                height: 24,
                marginLeft: 16,
                marginBottom: 140,
              }}
            />
            {items
              .filter(
                item =>
                  !item.roles || intersection(roles, item.roles).length > 0,
              )
              // Amine El Maachi Vectalia, restrict access ugly code
              .filter(item =>
                account?._id === '6647563417befce41aacb0f3'
                  ? item.id === 'tickets' || item.id === 'transactions'
                  : true,
              )
              // One access for Foughal, restrict access ugly code
              // .filter(item =>
              //   account?._id === '653799f8fc4c2b0628779a19'
              //     ? item.id === 'foughal-transactions'
              //     : true,
              // )
              .map(item => (
                <div
                  key={item.id}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    cursor: 'pointer',
                    backgroundColor:
                      activeItem === item.id ? colors.PRIMARY_DARK : null,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      width: 3,
                      borderTopRightRadius: 5,
                      borderBottomRightRadius: 5,
                      backgroundColor:
                        activeItem === item.id ? 'white' : colors.PRIMARY,
                    }}
                  ></div>
                  <Link to={item.link}>
                    <Menu.Item
                      style={{
                        ...styles.item,
                      }}
                      name={item.id}
                      active={activeItem === item.id}
                      onClick={this.handleItemClick}
                      as="span"
                    >
                      <ReactSVG
                        src={item.icon}
                        beforeInjection={svg => {
                          svg.setAttribute('width', '24');
                        }}
                      />
                      <label
                        style={{
                          ...styles.text,
                          cursor: 'pointer',
                          color: 'white',
                        }}
                      >
                        {t(item.label)}
                      </label>
                    </Menu.Item>
                  </Link>
                </div>
              ))}
          </Menu>
        </Sidebar>

        <Sidebar.Pusher
          style={{
            ...styles.pusher,
            width: opened ? '87%' : '95%',
          }}
          onClick={() => {
            if (window.screen.width <= 767 && opened) {
              toggleDrawer(false);
            }
          }}
        >
          <Segment style={{ height: '100%' }} basic>
            {children}
          </Segment>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    );
  }
}

const styles = {
  sidebar: {
    backgroundColor: colors.PRIMARY,
    justifyContent: 'center',
    overflow: 'hidden',
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    boxShadow: 'none',
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 22,
    width: 66,
    height: 56,
    margin: 0,
  },
  text: {
    paddingLeft: 25,
  },
  pusher: {
    height: '100%',
    // 60px = side menu width
    width: 'calc(100% - 60px)',
    overflow: 'auto',
  },
  pushable: {
    // 47px = top menu height -- Menu is now in the same line as the drawer
    border: 0,
    height: '100%',
    backgroundColor: colors.BACKGROUND_GREY,
    margin: 0,
  },
  sideMenuPadding: {
    paddingTop: 40,
  },
  burgerMenuContainer: {
    display: 'flex',
  },
};

SideMenu.defaultProps = {
  visible: true,
  children: null,
};

SideMenu.propTypes = {
  visible: PropTypes.bool,
  children: PropTypes.node,
  account: PropTypes.object,
};

export default withi18n('menu')(SideMenu);
